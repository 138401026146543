import { Container, Typography } from '@mui/material';
import React from 'react';
import Footer from '../../Component/footer/Footer';
import NavBar from '../../Component/navbar/NavBar';
import logo from '../../resources/kitchencorner/KC-preview.png';
import game from '../../resources/kitchencorner/KC_Game.png';
import levels from '../../resources/kitchencorner/KC_Levels.png';

export default function KitchenCorner() {
  return (
    <div>
      <NavBar />
      <div
        style={{
          position: 'relative'
        }}
      >
        <div
          style={{
            backgroundImage: `url(${logo})`
          }}
          className='landingBackground'
        />
        <Container maxWidth='lg' className='DownloadButtonContainer'>
          <Typography variant='h1'>KitchenCorner</Typography>
          <Typography variant='h3'>Be a Kitchen master!</Typography>
          <div
            onClick={() => {
              window.location.href = '/kitchencorner/download';
            }}
            className='mainGitHubButton'
            style={{
              justifyContent: 'center'
            }}
          >
            <Typography
              style={{
                paddingLeft: '1em',
                paddingRight: '1.5em',
                fontSize: '20px'
              }}
            >
              Download now!
            </Typography>
          </div>
        </Container>
      </div>
      <Container
        style={{
          marginBottom: '2vh'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant={'h1'}>KitchenCorner</Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '30vh',
            textAlign: 'center'
          }}
        >
          <Typography variant='body1'>
            KitchenKorner is a cooking game developed by two passionate creators: Clara X. and Guillaume S. (CptnFish). This project was
            born as a student initiative under the Epitech Hub program, with the primary goal of exploring and mastering the open-source
            Godot Engine. The Epitech Hub program provides students with the freedom to develop their projects, supported by guidance and
            assistance from the Epitech team. Serving as both a learning experience and a creative endeavor, KitchenKorner showcases the
            duo&apos;s collaboration and technical growth in game development.
          </Typography>
          <img
            alt='Moulmerman level'
            src={levels}
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '2vh',
              width: '90%'
            }}
          />
        </div>
      </Container>
      <Container
        style={{
          marginBottom: '2vh'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant={'h1'}>Multiplayer</Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '30vh',
            textAlign: 'center'
          }}
        >
          <Typography variant='body1'>
            Play with up to 4 players locally and dive into the action! Across three unique levels, your team must prepare and serve orders
            to satisfy hungry customers within a limited time. The more orders you fulfill, the more money your restaurant earns, unlocking
            opportunities to expand your culinary empire to exciting new locations even the most extraordinary and unexpected corners of the
            globe!
          </Typography>
          <img
            src={game}
            alt='Kitchen Corner Halloween level'
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '2vh',
              width: '90%'
            }}
          />
        </div>
      </Container>
      <Container
        style={{
          marginBottom: '2vh'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant={'h1'}>Sources</Typography>
        </div>
        <div>
          <Typography variant='body1'>Game Assets:</Typography>
          <Typography variant='body1'>
            &#x2022; Selection level :{' '}
            <a href={'https://www.kaylousberg.com/game-assets/medieval-hexagon'} target={'_blank'} rel='noreferrer'>
              Medieval Hexagon - KayLousberg
            </a>
          </Typography>
          <Typography variant='body1'>
            &#x2022; City Level :{' '}
            <a href={'https://www.kaylousberg.com/game-assets/city-builder-bits'} target={'_blank'} rel='noreferrer'>
              City Builder Bits - KayLousberg
            </a>
          </Typography>
          <Typography variant='body1'>
            &#x2022; Medieval Level :{' '}
            <a href={'https://www.kaylousberg.com/game-assets/dungeon-remastered'} target={'_blank'} rel='noreferrer'>
              Dungeon Remastered - KayLousberg
            </a>
          </Typography>
          <Typography variant='body1'>
            &#x2022; Kitchens :{' '}
            <a href={'https://www.kaylousberg.com/game-assets/restaurant-bits'} target={'_blank'} rel='noreferrer'>
              Restaurant Bits - KayLousberg
            </a>
          </Typography>
          <Typography variant='body1'>
            &#x2022; Halloween Level :{' '}
            <a href={'https://www.kaylousberg.com/game-assets/halloween-bits'} target={'_blank'} rel='noreferrer'>
              Halloween Bits - KayLousberg
            </a>
          </Typography>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
