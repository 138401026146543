import { Container, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import React, { useState } from 'react';
import Footer from '../../Component/footer/Footer';
import NavBar from '../../Component/navbar/NavBar';
import { GetIconPlatform, GetPlatformName } from '../../Component/utils/OsDetection';
import SnackBar from '../../Component/utils/SnackBar';
import { apiUrl } from '../../config';
import logo from '../../resources/kitchencorner/KC_Main.png';
import MoreInfo from './MoreInfo';

const projectId = '49564d35-0dd7-4449-8891-408ec1408eb5';
const projectVersion = '0.1';

export default function KitchenCornerDownload() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const icon = GetIconPlatform();

  return (
    <div>
      <NavBar />
      <div
        style={{
          position: 'relative'
        }}
      >
        <div
          style={{
            backgroundImage: `url(${logo})`,
            filter: 'blur(3px) brightness(0.8)'
          }}
          className='landingBackground'
        />
        <Container maxWidth='lg' className='mainGitHubButtonContainer'>
          <Typography variant='h1'>Be a master of cooking!</Typography>
          <Typography variant='h3'>
            In Kitchen Corner be ready to cook some delicious food for your client an be a kitchen world master.
          </Typography>
          <div
            onClick={() => {
              downloadProject(GetPlatformName().toLowerCase(), projectVersion, () => {
                setError(true);
                setLoading(false);
              });
              setLoading(true);
            }}
            className='mainGitHubButton'
          >
            {loading ? (
              <CircularProgress
                style={{
                  marginLeft: '8vh',
                  marginRight: '8vh'
                }}
              />
            ) : (
              <Typography
                style={{
                  paddingLeft: '1em',
                  paddingRight: '1.5em',
                  fontSize: '20px'
                }}
              >
                Download v{projectVersion}
              </Typography>
            )}
            {icon}
          </div>
        </Container>
      </div>
      <MoreInfo version={projectVersion} />
      <Footer />
      <SnackBar
        message='An error occured please try again later.'
        open={error}
        CloseCallback={() => {
          setError(false);
        }}
      />
    </div>
  );
}

export function startDownload(url: string, fileName: string, errorCallback: () => void) {
  try {
    axios
      .get(url, {
        method: 'GET',
        responseType: 'blob'
      })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName || 'no-name';
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        window.location.href = '/thanks';
      })
      .catch(() => {
        errorCallback();
      });
  } catch (e) {
    errorCallback();
  }
}

export function downloadProject(platform: string, version: string, errorCallback: () => void) {
  axios
    .get(apiUrl + '/api/download?id=' + projectId + '&v=' + version + '&platform=' + platform)
    .then((data) => {
      startDownload(data.data.url, data.data.fileName, errorCallback);
    })
    .catch(() => {
      errorCallback();
    });
}
