import { Container, Typography } from '@mui/material';
import React from 'react';

import ResponsiveListElement from '../Component/List/ResponsiveListElement';
import Footer from '../Component/footer/Footer';
import NavBar from '../Component/navbar/NavBar';
import Moul from '../resources/bomb_icon.png';
import KCImage from '../resources/kitchencorner/KC-preview.png';

export default function Games() {
  return (
    <div>
      <NavBar />
      <Container
        style={{
          textAlign: 'center',
          paddingTop: '20px',
          paddingBottom: '40px'
        }}
      >
        <Typography variant={'h1'}>Here you will find all games I’ve made</Typography>
      </Container>
      <Container
        style={{
          textAlign: 'left',
          maxWidth: '40em',
          position: 'relative',
          zIndex: '1',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <ResponsiveListElement
          projectName={'kitchencorner'}
          title={'Kitchen Corner - 2024'}
          image={KCImage}
          desc='Kitchen Corner is a multiplayer game. Your goal is to cook some delicious food and be the kitchen master.'
          learnEnabled={true}
          dlEnabled={true}
        />
        <ResponsiveListElement
          projectName={'moulmerman'}
          title={'Moulmerman - 2023'}
          image={Moul}
          desc='3D Bomberman made with ShipWreck Engine. Destroy your friends to be the last one.'
          learnEnabled={true}
        />
      </Container>
      <Footer />
    </div>
  );
}
