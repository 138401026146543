import { Container, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import Footer from '../../../Component/footer/Footer';
import NavBar from '../../../Component/navbar/NavBar';
import TwoSidedElements from '../../../Component/utils/TwoSidedElements';
import waterBlood from '../../../resources/water/water-blood.png';
import config from '../../../resources/water/water-configuration.png';
import waterNightGif from '../../../resources/water/water-gif.gif';
import waterNight from '../../../resources/water/water-night-bis.png';
import './WaterShader.css';

export default function Water() {
  const matches = useMediaQuery('(min-width:800px)');

  return (
    <div>
      <NavBar />
      <div
        style={{
          position: 'relative'
        }}
      >
        <div
          style={{
            backgroundImage: `url(${waterNightGif})`
          }}
          className='waterBackground'
        />
        <Container maxWidth='lg' className='waterTitle'>
          <Typography variant='h1'>Water Shader Project</Typography>
        </Container>
      </div>
      <Container
        style={{
          marginBottom: '2vh'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant={'h1'}>Water Shader</Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '30vh',
            textAlign: 'center'
          }}
        >
          <Typography variant='body1'>
            Water has always captivated me. My fascination ran so deep that I often found myself diving into games like Sea of Thieves and
            Assassin’s Creed: Black Flag, not for the gameplay, but simply to sail the virtual seas and marvel at the stunning water
            effects. I constantly wondered, &quot;How is it possible to create such realistic water in a video game?&quot;
            <br />
            <br />
            To take my first steps into water creation, I embarked on extensive research, studying how water has been rendered from the
            early days of 3D graphics to the cutting-edge techniques used in modern AAA games. Along the way, I encountered a wide variety
            of approaches from simple, basic shaders to highly complex formulas that, while beautiful, were too resource-intensive for
            real-time rendering. <br />
            <br />
            My goal was clear: to create a visually striking water shader in Unity3D that balanced beauty and performance. After much
            exploration and experimentation, I’m proud to present the result of my efforts!
          </Typography>
          <img
            alt='Water shader example'
            src={waterNight}
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '2vh',
              width: '90%'
            }}
          />
        </div>
      </Container>
      <Container
        style={{
          marginBottom: '2vh'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant={'h1'}>Customisation</Typography>
        </div>
        <TwoSidedElements
          leftElement={
            <img
              src={config}
              alt='Water shader configuration panel'
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '2vh',
                height: '40vh'
              }}
            />
          }
          rightElement={
            <Typography variant='body1' style={{ alignSelf: 'center', maxWidth: matches ? '600px' : 'auto' }}>
              This project offers the flexibility to create a diverse range of water styles, from lifelike, realistic oceans to wildly
              fantastical designs. With a multitude of adjustable parameters, you have full control over how your water looks and behaves.
              <br />
              <br /> You can tweak the mesh, modify how light interacts with the surface, and adjust a wide spectrum of colors from the
              overall hue to the subtle reflections shimmering on the water. <br />
              <br />
              Whether you&apos;re aiming for natural elegance or something entirely out of this world, this shader provides the tools to
              bring your creative vision to life.
            </Typography>
          }
        />
      </Container>
      <Container
        style={{
          marginBottom: '2vh'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant={'h1'}>Inspiration</Typography>
        </div>
        <div>
          <Typography variant='body1'>Here a list of my inspiration to create the shader:</Typography>
          <Typography variant='body1'>
            &#x2022;{' '}
            <a href={'https://www.youtube.com/watch?v=PH9q0HNBjT4&t=1047s&ab_channel=Acerola'} target={'_blank'} rel='noreferrer'>
              Acerola - How Games Fake Water
            </a>
          </Typography>
          <Typography variant='body1'>
            &#x2022;{' '}
            <a
              href={
                'https://developer.nvidia.com/gpugems/gpugems/part-i-natural-effects/chapter-1-effective-water-simulation-physical-models'
              }
              target={'_blank'}
              rel='noreferrer'
            >
              GPU gems (Chap 1)
            </a>
          </Typography>
          <Typography variant='body1'>
            &#x2022;{' '}
            <a href={'https://iquilezles.org/articles/fbm/'} target={'_blank'} rel='noreferrer'>
              Fractional Brownian Motion (FBM)
            </a>
          </Typography>
          <Typography variant='body1'>
            &#x2022;{' '}
            <a href={'http://filmicworlds.com/blog/everything-has-fresnel/'} target={'_blank'} rel='noreferrer'>
              Everything has Fresnel
            </a>
          </Typography>
          <Typography variant='body1'>
            &#x2022;{' '}
            <a href={'https://catlikecoding.com/unity/tutorials/flow/waves/'} target={'_blank'} rel='noreferrer'>
              Waves - Catlike Coding
            </a>
          </Typography>
        </div>
      </Container>
      <Container
        style={{
          marginBottom: '2vh'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '50vh',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              backgroundImage: `url(${waterBlood})`,
              width: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          />
        </div>
      </Container>
      <Footer />
    </div>
  );
}
