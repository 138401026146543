import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Container, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import SnackBar from '../../Component/utils/SnackBar';
import AppleIcon from '../../resources/icon_apple.svg';
import LinuxIcon from '../../resources/icon_linux.svg';
import WindowsIcon from '../../resources/icon_windows.svg';
import { downloadProject } from './KitchenCornerDownload';

export default function MoreInfo(props: { version: string }) {
  const [error, setError] = useState(false);
  const { version } = props;
  const matches = useMediaQuery('(min-width:800px)');

  function Tile(props: { name: string; icon: string; disableDl?: boolean | undefined }) {
    const { name, icon, disableDl } = props;
    const [loading, setLoading] = useState(false);

    return (
      <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '2vh'
        }}
      >
        <img
          src={icon}
          alt='logo'
          style={{
            height: '48px',
            fontSize: '20px'
          }}
        />
        <Typography variant='h3'>{name}</Typography>
        <div
          style={{
            marginTop: '2vh'
          }}
        >
          {disableDl !== undefined && disableDl ? (
            <Button disabled variant='contained'>
              Coming soon
            </Button>
          ) : (
            <LoadingButton
              loading={loading}
              variant='contained'
              onClick={() => {
                downloadProject(name.toLowerCase(), version, () => {
                  setError(true);
                  setLoading(false);
                });
                setLoading(true);
              }}
            >
              Download for {name}
            </LoadingButton>
          )}
        </div>
        <SnackBar
          message='An error occured please try again later.'
          open={error}
          CloseCallback={() => {
            setError(false);
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        textAlign: 'center'
      }}
    >
      <Typography variant='h1'>Other Platform</Typography>
      <Container
        style={{
          display: 'flex',
          flexDirection: matches ? 'row' : 'column',
          maxWidth: '60em',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '6vh',
          marginBottom: '10vh'
        }}
      >
        <Tile name='Windows' icon={WindowsIcon} />
        <Tile name='MacOs' icon={AppleIcon} />
        <Tile name='Linux' icon={LinuxIcon} />
      </Container>
      <Container style={{ marginBottom: '10vh' }}>
        <Typography variant='h1'>How to install?</Typography>
        <div style={{ textAlign: 'start' }}>
          <Typography variant='body1'>&#x2022; Extract the file you&apos;ve downloaded.</Typography>
          <Typography variant='body1'>&#x2022; Run the game and enjoy it!</Typography>
        </div>
      </Container>
    </div>
  );
}
