import { useMediaQuery } from '@mui/material';
import React from 'react';
import ListItemElement from './ListItemElement';
import MobileListItemElement from './MobileListItemElement';

export default function ResponsiveListElement(props: {
  projectName: string;
  title: string;
  infoLink?: string | undefined;
  image: string;
  desc: string;
  learnEnabled?: boolean | undefined;
  learnEndIcon?: React.ReactNode | undefined;
  dlEnabled?: boolean | undefined;
  dlEndIcon?: React.ReactNode | undefined;
}) {
  const { projectName, title, infoLink, image, desc, learnEnabled, dlEnabled, dlEndIcon, learnEndIcon } = props;
  const matches = useMediaQuery('(min-width:800px)');

  return (
    <div>
      {matches ? (
        <ListItemElement
          projectName={projectName}
          title={title}
          infoLink={infoLink}
          image={image}
          desc={desc}
          learnEnabled={learnEnabled}
          learnEndIcon={learnEndIcon}
          dlEnabled={dlEnabled}
          dlEndIcon={dlEndIcon}
        />
      ) : (
        <MobileListItemElement
          projectName={projectName}
          title={title}
          infoLink={infoLink}
          image={image}
          desc={desc}
          learnEnabled={learnEnabled}
          learnEndIcon={learnEndIcon}
          dlEnabled={dlEnabled}
          dlEndIcon={dlEndIcon}
        />
      )}
    </div>
  );
}
