import { Avatar, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function MobileListItemElement(props: {
  projectName: string;
  title: string;
  infoLink?: string | undefined;
  image: string;
  desc: string;
  learnEnabled?: boolean | undefined;
  learnEndIcon?: React.ReactNode | undefined;
  dlEnabled?: boolean | undefined;
  dlEndIcon?: React.ReactNode | undefined;
}) {
  const { projectName, title, infoLink, image, desc, learnEnabled, dlEnabled, dlEndIcon, learnEndIcon } = props;
  const navigation = useNavigate();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5vh', textAlign: 'center' }}>
      <div style={{ alignSelf: 'center' }}>
        <Avatar alt={projectName + ' image'} src={image} sx={{ width: 200, height: 200 }} />
      </div>
      <div style={{ marginTop: '3vh' }}>
        <Typography variant='h2'>{title}</Typography>
        <Typography variant='body1' style={{ textAlign: 'center' }}>
          {desc}
        </Typography>
        <div style={{ marginTop: '1vh' }}>
          {learnEnabled !== undefined && (
            <Button
              variant='contained'
              style={{ marginRight: '2vw' }}
              disabled={!learnEnabled}
              onClick={() => {
                if (infoLink !== undefined) {
                  window.open(infoLink, '_blank');
                  return;
                }
                navigation('/' + projectName.toLowerCase().replace(' ', ''));
              }}
              endIcon={learnEndIcon}
            >
              Learn More
            </Button>
          )}
          {dlEnabled !== undefined && (
            <Button
              color='secondary'
              variant='contained'
              onClick={() => navigation('/' + projectName.toLowerCase().replace(' ', '') + '/download')}
              disabled={!dlEnabled}
              endIcon={dlEndIcon}
            >
              Download
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
