import { Container, Typography } from '@mui/material';
import React from 'react';
import Footer from '../../Component/footer/Footer';
import NavBar from '../../Component/navbar/NavBar';
import game from '../../resources/moulmerman/Moul_game.png';
import selection from '../../resources/moulmerman/Moul_selection.png';

export default function Moulmerman() {
  return (
    <div>
      <NavBar />
      <div
        style={{
          position: 'relative'
        }}
      >
        <div
          style={{
            backgroundImage: `url(${game})`
          }}
          className='landingBackground'
        />
        <Container maxWidth='lg' className='MoulmermanTitleContainer'>
          <Typography variant='h1'>Moulmerman</Typography>
          <Typography variant='h3'>A bomberman like with ShipWreck Engine</Typography>
        </Container>
      </div>
      <Container
        style={{
          marginBottom: '2vh'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant={'h1'}>Moulmerman</Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '30vh',
            textAlign: 'center'
          }}
        >
          <Typography variant='body1'>
            Moulmerman is a game developed as part of a school project during my fourth year at Chung-Ang University in Seoul, South Korea.
            Created as the final assignment for a game development course, this project stands out as a fully handcrafted experience,
            featuring a custom-built game engine and the game itself. It showcases a deep understanding of game development fundamentals and
            a commitment to creating something unique from the ground up.
          </Typography>
          <iframe
            width='100%'
            height='400vw'
            src='https://www.youtube.com/embed/zvhSpreJKaM'
            title='Moulmerman - Demo'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerPolicy='strict-origin-when-cross-origin'
            allowFullScreen
            style={{ alignSelf: 'center', marginTop: '2vh' }}
          ></iframe>
        </div>
      </Container>
      <Container
        style={{
          marginBottom: '2vh'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant={'h1'}>Multiplayer</Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '35vh',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant='body1'>
            In the ruins of a once-thriving city, a mysterious virus has wiped out nearly all of humanity. You and a handful of survivors
            have scraped together a camp, clinging to life in a world turned to ash. But survival is monotonous, and boredom gnaws at your
            spirit. <br />
            One day, while scavenging the remnants of the past, you stumble upon a relic of joy: Bomberman. It’s the perfect distraction
            from the bleakness around you. Gather up to four players, face off in explosive battles, and fight for victory not for survival,
            but for a little spark of fun in a broken world.
          </Typography>
          <img
            src={selection}
            alt='Moulmerman selection player'
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '2vh',
              width: '90%'
            }}
          />
        </div>
      </Container>
      <Footer />
    </div>
  );
}
