import { Container, Typography } from '@mui/material';
import React from 'react';
import Footer from '../../Component/footer/Footer';
import NavBar from '../../Component/navbar/NavBar';
import logo from '../../resources/kitchencorner/KC_Halloween.jpg';

export default function Thanks() {
  return (
    <div>
      <NavBar />
      <div
        style={{
          position: 'relative'
        }}
      >
        <div
          style={{
            backgroundImage: `url(${logo})`
          }}
          className='landingBackground'
        />
        <Container className='mainGitHubButtonContainer'>
          <Typography variant='h1'>Thanks you!</Typography>
          <Typography variant='h3' style={{ marginBottom: '200px' }}>
            Your download is very important for us and give motivation to continue!
          </Typography>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
