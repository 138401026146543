import { useMediaQuery } from '@mui/material';
import React from 'react';

export default function TwoSidedElements(props: { leftElement: React.ReactNode; rightElement: React.ReactNode }) {
  const { leftElement, rightElement } = props;
  const matches = useMediaQuery('(min-width:800px)');

  return (
    <div>
      {matches ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          {leftElement}
          {rightElement}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {leftElement}
          {rightElement}
        </div>
      )}
    </div>
  );
}
